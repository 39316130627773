<template>
  <div class="rf-multiple-input-autocomplete">
    <RFInputAutocomplete :label="label" :color="color" @select="addValue" :value="value" :items="availableValues" multiple-input :disabled="occuped && occuped.length === list && list.length"/>
    <div class="values">
      <div class="value" :style="{ backgroundColor: color }" v-for="(value, index) in occuped" :key="index">
        {{value}}
        <font-awesome-icon class="cursor-pointer ml-2" :icon="['fas', 'times']" @click="removeValue(value)"/>
      </div>
    </div>
  </div>
</template>

<script>
import RFInputAutocomplete from '@/components/forms/RFInputAutocomplete'
import _difference from 'lodash/difference'

export default {
  name: 'RFMultipleInputAutocomplete',
  components: {
    RFInputAutocomplete,
  },
  props: {
    label: String,
    color: {
      type: String,
      default: '#24425B',
    },
    list: Array,
    property: String,
    occupedList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      value: null,
      values: [],
      occuped: [],
      availableValues: [],
    }
  },
  beforeMount() {
    this.values = this.extractValues(this.list)
    this.occuped = this.extractValues(this.occupedList)
    this.availableValues = _difference(this.values, this.occuped)
  },
  methods: {
    extractValues(list) {
      return list && list.length ? list.map(item => item[this.property]) : []
    },
    addValue(addedValue) {
      if (this.availableValues.includes(addedValue)) {
        this.occuped = [...this.occuped, addedValue]
        const index = this.availableValues.findIndex(value => value === addedValue)
        this.availableValues.splice(index, 1)
      }

      this.value = null
    },
    removeValue(removedValue) {
      let index = this.values.findIndex((value) => value === removedValue)
      this.availableValues = [...this.availableValues, this.values[index]]

      index = this.occuped.findIndex((value) => value === removedValue)
      this.occuped.splice(index, 1)
    },
  },
  watch: {
    availableValues: {
      handler() {
        const emittedValues = this.list.filter(item => this.occuped.some(occupedValue => occupedValue === item[this.property]))
        this.$emit('input', emittedValues)
      },
    },
  },

}
</script>

<style lang="scss" scoped>
.rf-multiple-input-autocomplete {
  .values {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .value {
      display: flex;
      align-items: center;
      @include font-style($montserrat-light, 'light', $font-10);
      color: $white;
      padding: 0 10px;
      border: 1px solid $black;
      margin-right: 10px;
      border-radius: 10px;
      margin-top: 5px;
      white-space: nowrap;
    }
  }
}
</style>